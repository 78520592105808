import * as React from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import polyglot from '../../../../Translator'
import { featuresTranslations } from './featuresTranslations'
import { isMobileDevice } from '../../../../Diagrams/utils/utils'

interface IProps {}

const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  height: calc(100vh - 65px);
  padding-left: 10px;
  padding-right: 10px;
  grid-template-areas:
    'aside'
    'section';
  grid-template-rows: 5fr 5fr;
  background-color: #f2f2f2;

  @media (min-width: 450px) {
    grid-template-areas: 'section aside';
    grid-template-rows: 1fr;
    grid-template-columns: 4fr 2fr;
  }
`

const Aside = styled.div`
  grid-area: aside;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* background-color: saddlebrown; */
`
const Section = styled.div`
  grid-area: section;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* background-color: saddlebrown; */
`

const Title = styled.div`
  font-weight: 300;
  font-size: 35px;
  color: #262626;
`
const Paragraph = styled.div`
  font-weight: 300;
  font-size: 20px;
`

const FeaturesTransactions: React.SFC<IProps> = props => {
  return (
    <Grid>
      <Aside>
        <Title>{polyglot.tr(featuresTranslations.transactionsSchemas)}</Title>
        <Paragraph>{polyglot.tr(featuresTranslations.transactionsSchemasPar)}</Paragraph>
      </Aside>
      <Section>
        <ReactPlayer
          style={{
            boxShadow: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)'
          }}
          url={isMobileDevice() ? 'https://youtu.be/TeY35vwIaT4' : require('../../../../videos/transactions.mp4')}
          playing={false}
          controls={true}
          loop={true}
          width="100%"
          height="auto"
          muted={true}
        />
      </Section>
    </Grid>
  )
}

export default FeaturesTransactions
