import React from 'react'
import { AppRoutes, getRoute } from '../../../../AppRoutes'
import polyglot from '../../../../Translator'
import {
	ImageLanding,
	LandingGrid,
	MainTitleLanding,
	ParagraphLanding,
	StyledLinkLanding,
	TitleBoxLanding
} from './LandingStyleComponents'
import { landingTranslations } from './landingTranslations'

const Landing = () => (
	<LandingGrid>
		<div>
			<TitleBoxLanding>
				<MainTitleLanding>{polyglot.tr(landingTranslations.orgchartsQuick)}</MainTitleLanding>
				<ParagraphLanding>
					<span>{polyglot.tr(landingTranslations.buildOrgcharts)}</span>
					<br />
					<span>{polyglot.tr(landingTranslations.usingShareholderData)}</span>
				</ParagraphLanding>
				<StyledLinkLanding to={getRoute(AppRoutes.SIGNUP)}>{polyglot.t('créerCompte')}</StyledLinkLanding>
			</TitleBoxLanding>
		</div>

		<ImageLanding>
			<img
				alt=''
				style={{
					width: '70%',
					height: 'auto',
					boxShadow: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)'
				}}
				src='https://i.imgur.com/hi5yska.png'
			/>
		</ImageLanding>
	</LandingGrid>
)

export default Landing
