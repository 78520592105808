import React from 'react'

const PaintingIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="60px" viewBox="0 0 79.155 86.127">
    <path
      d="M26.35915470123291,3.3295773506164554 c-8.8,7 -18.5,9.9 -23.2,13.5 c6.2,10.3 10.5,16.7 11.9,18.6 l22.5,-13.9 C35.55915470123291,17.829577350616454 27.159154701232907,2.729577350616455 26.35915470123291,3.3295773506164554 z"
      id="svg_1"
    />
    <rect x="45.334" y="16.622" transform="rotate(-121.614 37.93 35.91)" width="4.4" height="26.7" id="svg_2" />
    <rect
      x="48.635"
      y="20.523"
      transform="scale(-1) rotate(58.378 71.387 -72.26)"
      width="2.8"
      height="26.7"
      id="svg_3"
    />
    <path
      d="M29.25915470123291,47.829577350616454 c1,-0.1 2,-0.2 2.8,-0.2 l0.1,0 c2.8,0 4.8,3.6 6.7,7 l0.2,0.4 c0.2,0.30000000000000004 0.4,0.6000000000000001 0.5,0.9 c0.30000000000000004,0.5 0.5,1 0.8,1.4 c0.8,1.2 2.1,3.9 4.1,8.2 l0.8,1.6 c2.3,4.7 4.8,9.5 6,11.2 c2.1,2.6 3.7,3.9 4.9,3.9 c0.1,0 0.2,0 0.2,0 c0.7000000000000001,-0.1 1.2,-0.4 2,-0.9 l0.4,-0.2 c2,-1.3 3.2,-2 0.5,-7.8 c-1,-2.1 -5.2,-7.7 -8.3,-11.8 c-2.5,-3.3 -4.5,-6 -5.3,-7.3 c-0.2,-0.30000000000000004 -0.4,-0.7000000000000001 -0.7000000000000001,-1 c-0.30000000000000004,-0.4 -0.5,-0.8 -0.8,-1.3 c-2.2,-3.2 -4.7,-6.9 -3.4,-9.6 c0.2,-0.5 0.6000000000000001,-1.1 1,-1.7000000000000002 c0.2,-0.30000000000000004 0.30000000000000004,-0.5 0.5,-0.8 c1.4,-2.1 3.1,-4.8 2.2,-6.4 l-1.8,-2.9 L20.85915470123291,44.02957735061646 l1.8,2.9 c0.4,0.8 1.6,1.1 3.4,1.1 C27.159154701232907,48.02957735061646 28.25915470123291,47.929577350616455 29.25915470123291,47.829577350616454 zM52.55915470123291,71.12957735061646 c0.4,-0.30000000000000004 0.9,-0.5 1.4,-0.5 c1.6,-0.2 3.2,0.7000000000000001 4.1,2.3 c0.7000000000000001,1.1 0.9,2.5 0.6000000000000001,3.7 c-0.2,0.9 -0.8,1.6 -1.6,2 c-0.4,0.30000000000000004 -0.9,0.4 -1.3,0.5 c-0.1,0 -0.30000000000000004,0 -0.4,0 c-1.5,0 -2.8,-0.9 -3.7,-2.3 C50.45915470123291,74.72957735061645 50.85915470123291,72.22957735061645 52.55915470123291,71.12957735061646 z"
      id="svg_4"
    />
    <g id="svg_5">
      <path
        d="M45.15915470123291,53.12957735061646 c-0.30000000000000004,-0.4 -0.5,-0.8 -0.8,-1.3 c-0.9,-1.3 -1.8,-2.7 -2.5,-4 l-3.9,4.9 c0.30000000000000004,0.6000000000000001 0.7000000000000001,1.2 1,1.8 l0.2,0.4 c0.2,0.30000000000000004 0.4,0.6000000000000001 0.5,0.9 c0.30000000000000004,0.5 0.5,1 0.8,1.4 c0.30000000000000004,0.5 0.8,1.3 1.3,2.4 l4.2,-5.3 c-0.1,-0.1 -0.1,-0.2 -0.2,-0.30000000000000004 C45.65915470123291,53.829577350616454 45.35915470123291,53.52957735061646 45.15915470123291,53.12957735061646 z"
        id="svg_6"
      />
      <path
        d="M39.65915470123291,57.829577350616454 c-0.30000000000000004,-0.5 -0.6000000000000001,-1 -0.9,-1.5 c-0.2,-0.30000000000000004 -0.30000000000000004,-0.6000000000000001 -0.5,-0.9 l-0.2,-0.4 c-0.30000000000000004,-0.5 -0.5,-0.9 -0.8,-1.4 L16.65915470123291,79.72957735061645 l5.8,4.6 l18.8,-23.7 C40.55915470123291,59.32957735061646 40.05915470123291,58.429577350616455 39.65915470123291,57.829577350616454 z"
        id="svg_7"
      />
      <path
        d="M72.75915470123292,10.329577350616455 l-2,0.9 l-8.3,10.5 c-2.5,9.2 -10.1,12.8 -10.1,12.8 l-9.9,12.5 c0.7000000000000001,1.5 1.8,3 2.7,4.4 c0.30000000000000004,0.5 0.5,0.8 0.8,1.2 c0.2,0.4 0.5,0.7000000000000001 0.7000000000000001,1 l11.5,-14.6 c2.5,-9.1 9.8,-13 9.8,-13 l8.3,-10.5 l0.5,-2.1 L72.75915470123292,10.329577350616455 z"
        id="svg_8"
      />
    </g>
  </svg>
)

export default PaintingIcon
