import React from 'react';
import SignUpNow from '../../Base/Home/Sections/CallToAction/SignUpNow';
import './Home.scss';
import FeatureAutomaticLayout from './Sections/Features/FeatureAutomaticLayout';
import FeatureCloudBased from './Sections/Features/FeatureCloudBased';
import FeaturesManageShares from './Sections/Features/FeatureManageShares';
import FeatureOther from './Sections/Features/FeatureOther';
import FeaturesTransactions from './Sections/Features/FeatureTransactions';
import Landing from './Sections/Landing/Landing';
import Optimize from './Sections/Optimize/Optimize';

// const translations = {
// 	redox: {
// 		en: 'Redox | Create corporate org charts, quickly.',
// 		fr: 'Redox | Créez des organigrammes corporatifs, rapidement.'
// 	},
// 	redoxDescription: {
// 		en: 'Redox is a software that builds corporate org charts using data from the shareholding of a corporation.',
// 		fr:
// 			"Redox est un logiciel qui construit des organigrammes corporatifs à l'aide de données provenant de l'actionnariat d'une entreprise."
// 	},
// 	redoxKeywords: {
// 		en:
// 			'Redox,Redox automation,Redox corporate org charts,Redox orgcharts,Redox org charts,Redox automation tools for lawyers,orgcharts,org charts',
// 		fr:
// 			"Redox,Redox automatisation,Redox organigrammes,Redox organigrammes corporatifs,Redox diagrammes d'entreprise,diagrammes,entreprise,outils,automatisation,avocats"
// 	}
// };

export default function Home() {
	return (
		<div id="home">
			{/* <Helmet>
        <title>{polyglot.tr(translations.redox)}</title>
        <meta name="description" content={polyglot.tr(translations.redoxDescription)} />
        <meta name="keywords" content={polyglot.tr(translations.redoxKeywords)} />
      </Helmet> */}
			<Landing />
			<Optimize />
			<FeatureAutomaticLayout />
			<FeaturesManageShares />
			<FeatureCloudBased />
			<FeaturesTransactions />
			<FeatureOther />
			<SignUpNow />
		</div>
	);
}
