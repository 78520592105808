import React from 'react'
import { Col, Grid, Row } from 'react-bootstrap'
import Reveal from 'react-reveal'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { AppRoutes, getRoute } from '../../../../AppRoutes'
import polyglot from '../../../../Translator'
import BalanceSVG from '../../../svg/BalanceSVG'
import RobotSVG from '../../../svg/RobotSVG'
import RocketSVG from '../../../svg/RocketSVG'
import { optimizeTranslations } from './optimizeTranslations'

const StyledLink = styled(Link)`
  color: #ffffff;
  font-size: 16px;
  padding: 15px 30px;
  width: 280px;
  height: 80px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: #29c086;
  &:hover {
    background-color: #229e6d;
  }
`
const OptimizeSection = styled.div`
  /* padding-top: 12vh; */
  padding-top: 100px;
  min-height: 700px;
  width: 100%;
  /* overflow: hidden; */
  background-color: #22313f;
`
const Box = styled.div`
  padding-top: 20px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 5px;
  opacity: 0.8;
  padding: 40px;
  height: 472px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 450px) {
    font-size: 20px;
    height: 400px;
    padding: 20px;
  }
`

const Paragraph = styled.div`
  font-weight: 300;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
  @media (max-width: 450px) {
    font-size: 16px;
  }
`
const MainTitle = styled.h2`
  text-align: center;
  color: #ffffff;
  font-weight: 300;
  font-size: 34px;
`
const Title = styled.h3`
  font-weight: 300;
  font-size: 30px;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.85);
  @media (max-width: 450px) {
    font-size: 24px;
  }
`
const Hr = styled.hr`
  border-width: 1px;
  max-width: 50px;
  border-color: #ffffff;
`
const CallToActionDiv = styled.div`
  height: 200;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Optimize = () => (
  <OptimizeSection>
    <Grid>
      <MainTitle>{polyglot.tr(optimizeTranslations.whyRedox)}</MainTitle>
      <Hr />
      <Col xs={10} xsOffset={1} md={4} mdOffset={0}>
        <Box>
          <Reveal effect="animated zoomIn">
            <RobotSVG height="64px" />
          </Reveal>
          <Title>{polyglot.tr(optimizeTranslations.computerAssistance)}</Title>
          <Paragraph>{polyglot.tr(optimizeTranslations.delegateToYourMachineP1)}</Paragraph>
        </Box>
      </Col>
      <Col xs={10} xsOffset={1} md={4} mdOffset={0}>
        <Box>
          <Reveal effect="animated zoomIn">
            <RocketSVG height="64px" />
          </Reveal>
          <Title>{polyglot.tr(optimizeTranslations.web)}</Title>
          <Paragraph>{polyglot.tr(optimizeTranslations.getStartedP1)}</Paragraph>
        </Box>
      </Col>
      <Col xs={10} xsOffset={1} md={4} mdOffset={0}>
        <Box>
          <Reveal effect="animated zoomIn">
            <BalanceSVG height="64px" />
          </Reveal>
          <Title>{polyglot.tr(optimizeTranslations.builtByLawyers)}</Title>
          <Paragraph>{polyglot.tr(optimizeTranslations.builtByLawyersP1)}</Paragraph>
        </Box>
      </Col>
      <Row>
        <Col xs={12} xsOffset={0} md={12} mdOffset={0}>
          <CallToActionDiv>
            <StyledLink to={getRoute(AppRoutes.SIGNUP)}>{polyglot.t('créerCompte')}</StyledLink>
          </CallToActionDiv>
        </Col>
      </Row>
    </Grid>
  </OptimizeSection>
)

export default Optimize
