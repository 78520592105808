import React from 'react'

const RocketSVG = props => (
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 69.83 64.6"
    preserveAspectRatio=" none "
    height={props.height}
  >
    <title>test</title>
    <path
      d="M22.53,41.48,32.7,33l13-10.8a1.13,1.13,0,0,0-1.38-1.78h0L31.26,31.3,21.09,39.75,18.8,37a3.42,3.42,0,0,1-.5-.8l-2.7,2.25A1.12,1.12,0,0,0,15.43,40h0l2.16,2.6L14.81,45a1.12,1.12,0,0,0,1.33,1.81l.1-.08,2.82-2.34L21.22,47a1.12,1.12,0,0,0,1.58.15h0l2.69-2.24a3.42,3.42,0,0,1-.7-.63Z"
      transform="translate(0.01)"
      style={{ fill: '#fff' }}
    />
    <path
      d="M32.35,64.23,43.19,51.67a14.13,14.13,0,0,0,3-5.94l4.2-17.63L37.66,38.72l-7.58,5.22.37,19.6a1.08,1.08,0,0,0,1.89.69Z"
      transform="translate(0.01)"
      style={{ fill: '#999' }}
    />
    <path
      d="M26.39,25.6l.14-.14,12.84-10.7-18.11.91a14.13,14.13,0,0,0-6.39,1.93L.54,25.93a1.08,1.08,0,0,0,.33,2l19.2,3.94Z"
      transform="translate(0.01)"
      style={{ fill: '#999' }}
    />
    <path d="M69.2,4.67,69.1,5Z" transform="translate(0.01)" style={{ fill: '#ff4f19' }} />
    <path
      d="M50.53,28.24l10.89-9c4.8-4,7.21-10.76,8.33-14.69a40.25,40.25,0,0,1-2.81,7.39L62.56,6.64,58.15,10.3a4.32,4.32,0,1,1-6.6,5.56h0L45.2,21.14a1.11,1.11,0,0,1-.13,1.57h0L32.18,33.35l5,6-8.32,5.73.74-.52,7.81-5.38Z"
      transform="translate(0.01)"
      style={{ fill: '#ccc' }}
    />
    <polygon points="26.4 25.59 26.4 25.59 26.53 25.45 26.4 25.59" style={{ fill: '#ff4f19' }} />
    <path d="M65.44.6h0Z" transform="translate(0.01)" style={{ fill: '#e5001e' }} />
    <path
      d="M25.8,25.76l5,6L43.65,21a1.11,1.11,0,0,1,1.57.13h0l6.34-5.27A4.32,4.32,0,0,1,58.2,10.4l4.41-3.67L58.22,1.46A40.17,40.17,0,0,1,66,0C61.93.39,54.84,1.57,50,5.56l-10.86,9-13.24,11Z"
      transform="translate(0.01)"
      style={{ fill: '#d9dadb' }}
    />
    <path
      d="M37.35,39.22l-5-6L22.3,41.6l2.25,2.73a3.39,3.39,0,0,0,.69.63A3.33,3.33,0,0,0,29,45Z"
      transform="translate(0.01)"
      style={{ fill: '#bababa' }}
    />
    <path
      d="M21.21,39.92,31.59,31.3l-5.13-6.15h0l-6.71,6.69-.66.66a3.43,3.43,0,0,0-.72,3.83,3.49,3.49,0,0,0,.51.82Z"
      transform="translate(0.01)"
      style={{ fill: '#ccc' }}
    />
    <circle cx="54.61" cy="13.37" r="4.72" transform="translate(4.07 37.98) rotate(-39.72)" style={{ fill: '#fff' }} />
    <path
      d="M62.42,6.83,69.06,1.3A3.35,3.35,0,0,0,66.24.1h-.4A40.23,40.23,0,0,0,58,1.52Z"
      transform="translate(0.01)"
      style={{ fill: '#ccc' }}
    />
    <path
      d="M66.79,12.1a40.24,40.24,0,0,0,2.81-7.39h0l.1-.38a3.35,3.35,0,0,0-.65-3L62.42,6.82Z"
      transform="translate(0.01)"
      style={{ fill: '#b6b7ba' }}
    />
  </svg>
)

export default RocketSVG
