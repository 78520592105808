import * as React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { AppRoutes, getRoute } from '../../../../AppRoutes'
import polyglot from '../../../../Translator'

interface IProps {}

const Container = styled.div`
  height: 600px;
  background-color: #22313f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const signUpTranslations = {
  readyToJoinUs: { en: 'Ready to join us? ', fr: 'Prêt à vous joindre à nous?' }
}
const StyledLink = styled(Link)`
  color: #ffffff;
  font-size: 16px;
  padding: 15px 30px;
  width: 280px;
  height: 80px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: #29c086;
  &:hover {
    background-color: #229e6d;
  }
`
const SignUpNow: React.SFC<IProps> = props => {
  return (
    <Container>
      <div style={{ color: 'white', fontWeight: 300, fontSize: '32px', marginBottom: '20px' }}>
        {polyglot.tr(signUpTranslations.readyToJoinUs)}
      </div>
      <StyledLink to={getRoute(AppRoutes.SIGNUP)}>{polyglot.t('créerCompte')}</StyledLink>
    </Container>
  )
}

export default SignUpNow
