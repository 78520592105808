import React from 'react'

const Collaborate = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="60px" viewBox="0 0 71 79">
    <g id="svg_1">
      <path
        d="M19.24398973083496,77.21238626861572 c-9.033,0 -16.382,-7.349 -16.382,-16.381 c0,-9.033 7.349,-16.382 16.382,-16.382 c9.033,0 16.382,7.349 16.382,16.382 C35.62598973083496,69.86338626861573 28.276989730834963,77.21238626861572 19.24398973083496,77.21238626861572 zM19.24398973083496,45.87738626861572 c-8.246,0 -14.954,6.708 -14.954,14.954 c0,8.245 6.708,14.953 14.954,14.953 S34.19798973083496,69.07638626861572 34.19798973083496,60.83138626861572 C34.19798973083496,52.58538626861572 27.48898973083496,45.87738626861572 19.24398973083496,45.87738626861572 z"
        id="svg_2"
      />
      <path
        d="M54.23098973083496,52.80538626861572 c-7.977,0 -14.466,-6.489 -14.466,-14.465 s6.489,-14.465 14.466,-14.465 s14.466,6.489 14.466,14.465 S62.20798973083497,52.80538626861572 54.23098973083496,52.80538626861572 zM54.23098973083496,25.30238626861572 c-7.189,0 -13.038,5.849 -13.038,13.037 s5.849,13.037 13.038,13.037 s13.038,-5.849 13.038,-13.037 S61.419989730834956,25.30238626861572 54.23098973083496,25.30238626861572 z"
        id="svg_3"
      />
      <path
        d="M32.45298973083496,24.465386268615724 c-6.129,0 -11.116,-4.987 -11.116,-11.116 c0,-6.129 4.987,-11.116 11.116,-11.116 c6.129,0 11.116,4.987 11.116,11.116 C43.568989730834964,19.478386268615722 38.58298973083496,24.465386268615724 32.45298973083496,24.465386268615724 zM32.45298973083496,3.661386268615722 c-5.3420000000000005,0 -9.688,4.346 -9.688,9.688 c0,5.3420000000000005 4.346,9.688 9.688,9.688 c5.341,0 9.688,-4.346 9.688,-9.688 C42.14098973083496,8.007386268615722 37.79398973083496,3.661386268615722 32.45298973083496,3.661386268615722 z"
        id="svg_4"
      />
      <g id="svg_5">
        <g id="svg_6">
          <g id="svg_7">
            <circle cx="32.453" cy="8.847" r="3.2" id="svg_8" />
            <path
              d="M32.45298973083496,12.584386268615724 c-2.06,0 -3.7350000000000003,-1.677 -3.7350000000000003,-3.737 c0,-2.06 1.6760000000000002,-3.7350000000000003 3.7350000000000003,-3.7350000000000003 c2.06,0 3.736,1.6760000000000002 3.736,3.7350000000000003 C36.18898973083496,10.907386268615724 34.51298973083496,12.584386268615724 32.45298973083496,12.584386268615724 zM32.45298973083496,6.182386268615723 c-1.469,0 -2.665,1.195 -2.665,2.665 c0,1.47 1.195,2.6660000000000004 2.665,2.6660000000000004 c1.47,0 2.665,-1.196 2.665,-2.6660000000000004 C35.117989730834964,7.378386268615724 33.92298973083496,6.182386268615723 32.45298973083496,6.182386268615723 z"
              id="svg_9"
            />
          </g>
          <g id="svg_10">
            <path
              d="M36.89698973083496,18.443386268615725 c0,2.002 -8.886,2.002 -8.886,0 c0,-2.861 1.9889999999999999,-5.094 4.442,-5.094 C34.90598973083496,13.349386268615724 36.89698973083496,15.582386268615721 36.89698973083496,18.443386268615725 z"
              id="svg_11"
            />
            <path
              d="M32.45398973083496,20.480386268615725 c-2.407,0 -4.979,-0.535 -4.979,-2.037 c0,-3.157 2.186,-5.63 4.978,-5.63 c2.792,0 4.979,2.473 4.979,5.63 C37.431989730834964,19.94538626861572 34.85998973083496,20.480386268615725 32.45398973083496,20.480386268615725 zM32.45298973083496,13.884386268615724 c-2.19,0 -3.907,2.003 -3.907,4.559 c0,0.3370000000000001 1.342,0.9660000000000001 3.908,0.9660000000000001 c2.565,0 3.907,-0.6280000000000001 3.907,-0.9660000000000001 C36.36098973083496,15.887386268615721 34.64498973083496,13.884386268615724 32.45298973083496,13.884386268615724 z"
              id="svg_12"
            />
          </g>
        </g>
      </g>
      <g id="svg_13">
        <g id="svg_14">
          <g id="svg_15">
            <circle cx="54.287" cy="32.946" r="4.717" id="svg_16" />
            <path
              d="M54.28698973083496,38.199386268615726 c-2.8970000000000002,0 -5.253,-2.356 -5.253,-5.253 c0,-2.8970000000000002 2.356,-5.255 5.253,-5.255 c2.896,0 5.253,2.357 5.253,5.255 C59.53998973083496,35.84238626861572 57.18298973083496,38.199386268615726 54.28698973083496,38.199386268615726 zM54.28698973083496,28.76238626861572 c-2.306,0 -4.182,1.8760000000000001 -4.182,4.184 c0,2.306 1.8760000000000001,4.182 4.182,4.182 s4.182,-1.8760000000000001 4.182,-4.182 C58.46898973083496,30.639386268615723 56.59198973083497,28.76238626861572 54.28698973083496,28.76238626861572 z"
              id="svg_17"
            />
          </g>
          <g id="svg_18">
            <path
              d="M60.83898973083497,46.70738626861572 c0,2.954 -13.103,2.954 -13.103,0 c0,-4.218 2.933,-7.513 6.55,-7.513 C57.904989730834956,39.19438626861572 60.83898973083497,42.489386268615725 60.83898973083497,46.70738626861572 z"
              id="svg_19"
            />
            <path
              d="M54.287989730834965,49.458386268615726 c-3.27,0 -7.087,-0.7200000000000001 -7.087,-2.7510000000000003 c0,-4.513 3.112,-8.048 7.085,-8.048 c3.975,0 7.088,3.535 7.088,8.048 C61.374989730834955,48.73838626861572 57.55698973083496,49.458386268615726 54.287989730834965,49.458386268615726 zM54.28698973083496,39.730386268615725 c-3.373,0 -6.015,3.064 -6.015,6.977 c0,0.811 2.417,1.6800000000000002 6.016,1.6800000000000002 c3.599,0 6.016,-0.8690000000000001 6.016,-1.6800000000000002 C60.30398973083496,42.794386268615725 57.660989730834956,39.730386268615725 54.28698973083496,39.730386268615725 z"
              id="svg_20"
            />
          </g>
        </g>
      </g>
      <g id="svg_21">
        <g id="svg_22">
          <g id="svg_23">
            <circle cx="19.404" cy="53.931" r="5.727" id="svg_24" />
            <path
              d="M19.40498973083496,60.194386268615716 c-3.454,0 -6.263,-2.809 -6.263,-6.263 c0,-3.454 2.81,-6.264 6.263,-6.264 c3.453,0 6.2620000000000005,2.81 6.2620000000000005,6.264 C25.666989730834963,57.38538626861572 22.85698973083496,60.194386268615716 19.40498973083496,60.194386268615716 zM19.40498973083496,48.73838626861572 c-2.863,0 -5.192,2.33 -5.192,5.193 c0,2.863 2.33,5.192 5.192,5.192 c2.862,0 5.191,-2.329 5.191,-5.192 C24.595989730834958,51.06738626861572 22.266989730834958,48.73838626861572 19.40498973083496,48.73838626861572 z"
              id="svg_25"
            />
          </g>
          <g id="svg_26">
            <path
              d="M27.35698973083496,70.36138626861572 c0,3.584 -15.907,3.584 -15.907,0 c0,-5.121 3.5620000000000003,-9.121 7.954,-9.121 C23.79598973083496,61.24038626861572 27.35698973083496,65.24038626861572 27.35698973083496,70.36138626861572 z"
              id="svg_27"
            />
            <path
              d="M19.40398973083496,73.58438626861572 c-3.916,0 -8.489,-0.8440000000000001 -8.489,-3.223 c0,-5.415 3.729,-9.657 8.489,-9.657 c4.76,0 8.488,4.242 8.488,9.657 C27.892989730834962,72.74138626861573 23.319989730834962,73.58438626861572 19.40398973083496,73.58438626861572 zM19.40498973083496,61.77538626861572 c-4.16,0 -7.418,3.771 -7.418,8.586 c0,1.187 3.3280000000000003,2.152 7.418,2.152 c4.09,0 7.417,-0.9650000000000001 7.417,-2.152 C26.821989730834964,65.54738626861572 23.56398973083496,61.77538626861572 19.40498973083496,61.77538626861572 z"
              id="svg_28"
            />
          </g>
        </g>
      </g>
      <path
        d="M45.068989730834964,28.49138626861572 c-0.14600000000000002,0 -0.29200000000000004,-0.06000000000000001 -0.3980000000000001,-0.17800000000000002 l-5.921,-6.583 c-0.19700000000000004,-0.22000000000000003 -0.18000000000000002,-0.558 0.04100000000000001,-0.7560000000000001 c0.21900000000000003,-0.19700000000000004 0.557,-0.18000000000000002 0.7560000000000001,0.04100000000000001 l5.921,6.583 c0.19700000000000004,0.22000000000000003 0.18000000000000002,0.558 -0.04100000000000001,0.7560000000000001 C45.32498973083496,28.44538626861572 45.196989730834964,28.49138626861572 45.068989730834964,28.49138626861572 z"
        id="svg_29"
      />
      <path
        d="M33.16798973083496,54.25838626861572 c-0.19200000000000003,0 -0.37900000000000006,-0.10400000000000001 -0.47500000000000003,-0.28600000000000003 c-0.137,-0.262 -0.037000000000000005,-0.5860000000000001 0.22500000000000003,-0.7230000000000001 l11.247,-5.904 c0.264,-0.138 0.5870000000000001,-0.036000000000000004 0.7240000000000001,0.22500000000000003 c0.137,0.262 0.037000000000000005,0.5860000000000001 -0.22500000000000003,0.7230000000000001 l-11.247,5.904 C33.33698973083496,54.23938626861572 33.251989730834964,54.25838626861572 33.16798973083496,54.25838626861572 z"
        id="svg_30"
      />
    </g>
  </svg>
)

export default Collaborate
