export const optimizeTranslations = {
  whyRedox: {
    en: 'Why Redox?',
    fr: 'Pourquoi Redox?'
  },
  computerAssistance: {
    en: 'Productivity',
    fr: 'Productivité'
  },
  delegateToYourMachine: {
    en: 'Tailored Fit',
    fr: 'Sur Mesure'
  },
  delegateToYourMachineP1: {
    en:
      'Redox was designed to build specifically corporate org charts. Thus, we elaborated shortcuts and features adapted for tax professionnals in order to increase their productivity.',
    fr:
      "Redox est conçu pour bâtir spécifiquement des organigrammes corporatifs. Ainsi, nous avons pu élaborer des raccourcis et des fonctionnalités spécialement adaptées aux fiscalistes et comptables dans l'objectif d'accroître leur productivité."
  },
  web: {
    en: 'Simple Integration',
    fr: 'Intégration simple'
  },
  getStartedP1: {
    en:
      'Integrating Redox to your firm is simple and fast. Thanks to internet : no download is needed. You and your team can access our app through your favorite browser (Safari, Chrome, etc.).',
    fr:
      "Intégrer Redox à votre firme c'est simple et rapide. Grâce à internet, aucun téléchargement n'est nécessaire. Vous et votre équipe pouvez accèder à l'app via votre fureteur favori (Safari, Chrome, etc.)."
  },

  builtByLawyers: {
    en: 'Built by Tax Professionnals',
    fr: 'Pensé Par Des Fiscalistes'
  },
  builtByLawyersP1: {
    en:
      "Our team of professionals elaborated simple solutions tailored to the needs of today's and tomorrow's law practices.",
    fr:
      "Notre équipe de professionnels a élaboré des solutions simples et conçues sur mesure pour les cabinets d'aujourd'hui et de demain."
  }
}
