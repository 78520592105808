import React from 'react'

const AutomaticLegend = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="60px" viewBox="0 0 62.883 64.358">
    <g data-name="chart-financial-business-finance" id="svg_1">
      <polygon
        points="54 60 54 59 52 59 52 60 44 60 44 59 42 59 42 60 33 60 33 59 31 59 31 60 23 60 23 59 21 59 21 60 12 60 12 59 10 59 10 60 4 60 4 54 5 54 5 52 4 52 4 44 5 44 5 42 4 42 4 33 5 33 5 31 4 31 4 22 5 22 5 20 4 20 4 13 5 13 5 11 4 11 4 2 2 2 2 62 62 62 62 60 54 60"
        id="svg_2"
      />
      <path d="M10,50h2V40h2V24H12V14H10V24H8V40h2Zm0-24h2V38H10Z" id="svg_3" />
      <path d="M31,40h2V32h2V18H33V7H31V18H29V32h2Zm0-20h2V30H31Z" id="svg_4" />
      <path d="M42,52h2V49h2V41H44V32H42v9H40v8h2Zm0-9h2v4H42Z" id="svg_5" />
      <path d="M52,44h2V34h2V26H54V12H52V26H50v8h2Zm0-16h2v4H52Z" id="svg_6" />
      <path d="M21,50h2V44h2V33H23V28H21v5H19V44h2Zm0-15h2v7H21Z" id="svg_7" />
    </g>
  </svg>
)

export default AutomaticLegend
