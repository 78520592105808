import React from 'react'

const SavePdf = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="58" height="88">
    <rect id="backgroundrect" width="100%" height="100%" fill="none" />
    <g className="currentLayer">
      <path d="M58,88 H0 V0 h27.707 L58,30.293 V88 zM1,87 h56 V30.707 L27.293,1 H1 V87 z" id="svg_1" />
      <path
        d="M57.784000000000006,31 H27 V0.18700000000000028 h0.5 c0.06400000000000003,-0.010000000000000004 0.10800000000000003,-0.015000000000000006 0.15300000000000005,-0.017000000000000008 l0.21700000000000005,-0.008000000000000004 l0.15300000000000005,0.15400000000000005 L57.650000000000006,29.942 l0.016000000000000007,0.18100000000000005 c0.012000000000000004,0.14300000000000004 0.019000000000000006,0.22300000000000006 0.030000000000000013,0.3010000000000001 L57.784000000000006,31 zM28,30 h28.294 L28,1.7069999999999999 V30 z"
        id="svg_2"
      />
      <path
        d="M13.999000000000002,37.67 c0.807,-0.135 1.863,-0.25 3.208,-0.25 c1.6520000000000001,0 2.862,0.3850000000000001 3.6310000000000002,1.076 c0.7110000000000002,0.6150000000000002 1.133,1.557 1.133,2.708 c0,1.173 -0.3460000000000002,2.095 -0.999,2.7680000000000002 c-0.8840000000000002,0.9410000000000002 -2.325,1.42 -3.958,1.42 c-0.5,0 -0.9600000000000002,-0.020000000000000007 -1.3450000000000002,-0.11500000000000003 v5.188 h-1.6710000000000005 L13.999000000000002,37.67 L13.999000000000002,37.67 zM15.670000000000002,43.913 c0.3650000000000001,0.09700000000000006 0.8260000000000002,0.135 1.383,0.135 c2.017,0 3.247,-0.979 3.247,-2.766 c0,-1.71 -1.21,-2.537 -3.054,-2.537 c-0.7310000000000002,0 -1.288,0.05800000000000002 -1.576,0.134 V43.913 z"
        id="svg_3"
      />
      <path
        d="M24.226,37.688 c1.018,-0.15300000000000005 2.229,-0.269 3.5540000000000003,-0.269 c2.402,0 4.111,0.557 5.246,1.614 c1.151,1.056 1.824,2.555 1.824,4.649 c0,2.114 -0.6520000000000002,3.841 -1.863,5.034 c-1.211,1.209 -3.209,1.863 -5.724,1.863 c-1.191,0 -2.19,-0.05800000000000002 -3.036,-0.15400000000000005 V37.688 zM25.897,49.141000000000005 c0.4220000000000001,0.07600000000000003 1.037,0.09400000000000006 1.6900000000000004,0.09400000000000006 c3.574,0 5.515,-1.995 5.515,-5.492 c0.019000000000000006,-3.055 -1.711,-4.997 -5.246,-4.997 c-0.8640000000000002,0 -1.518,0.07800000000000003 -1.959,0.17300000000000001 V49.141000000000005 z"
        id="svg_4"
      />
      <path
        d="M37.027,37.515 h6.974 v1.403 h-5.302 v4.304 h4.896 v1.383 h-4.896 v5.859 h-1.6720000000000004 V37.515 z"
        id="svg_5"
      />
    </g>
  </svg>
)

export default SavePdf
