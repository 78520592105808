import * as React from 'react'
import styled from 'styled-components'
import polyglot from '../../../../Translator'
import AutomaticLegend from '../../../svg/AutomaticLegend'
import Collaborate from '../../../svg/Collaborate'
import ConvertIcon from '../../../svg/ConvertIcon'
import Keyboard from '../../../svg/Keyboard'
import LanguageBook from '../../../svg/LanguageBook'
import MicrosoftOffice from '../../../svg/MicrosoftOffice'
import PaintingIcon from '../../../svg/PaintingIcon'
import SavePDF from '../../../svg/SavePDF'
import { featuresTranslations } from './featuresTranslations'

interface IProps {}

const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-areas:
    'box1'
    'box2'
    'box3'
    'box4'
    'box5'
    'box6'
    'box7'
    'box8';
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;

  @media (min-width: 450px) {
    height: calc(100vh - 65px);
    grid-template-areas: 'box1 box2' 'box3 box4' 'box5 box6' 'box7 box8';
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    margin-top: 150px;
    margin-bottom: 150px;
    margin-left: 20vw;
    margin-right: 20vw;
  }
`
const Box = styled.div`
  background-color: #f2f2f2;
  margin: 20px;
  padding: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`

const Box1 = styled(Box)`
  grid-area: box1;
`
const Box2 = styled(Box)`
  grid-area: box2;
`
const Box3 = styled(Box)`
  grid-area: box3;
`
const Box4 = styled(Box)`
  grid-area: box4;
`
const Box5 = styled(Box)`
  grid-area: box5;
`
const Box6 = styled(Box)`
  grid-area: box6;
`
const Box7 = styled(Box)`
  grid-area: box7;
`
const Box8 = styled(Box)`
  grid-area: box8;
`

const Title = styled.div`
  font-weight: 300;
  font-size: 25px;
  color: #262626;
`
const Paragraph = styled.div`
  font-weight: 300;
  font-size: 16px;
`
const Hr = styled.hr`
  border-width: 1px;
  max-width: 50px;
`

const MainTitle = styled.div`
  text-align: center;
  font-weight: 300;
  font-size: 34px;
  height: 70px;
  color: #262626;
`

const FeaturesOther: React.SFC<IProps> = () => {
  return (
    <div style={{ marginTop: 150 }}>
      <MainTitle>{polyglot.tr(featuresTranslations.complete)}</MainTitle>
      <Hr />
      <Grid>
        <Box1>
          <SavePDF />
          <Title> {polyglot.tr(featuresTranslations.export)}</Title>
          <Paragraph>{polyglot.tr(featuresTranslations.exportPar)}</Paragraph>
        </Box1>
        <Box2>
          <Collaborate />
          <Title> {polyglot.tr(featuresTranslations.entity)}</Title>
          <Paragraph>{polyglot.tr(featuresTranslations.entityPar)}</Paragraph>
        </Box2>
        <Box3>
          <MicrosoftOffice />
          <Title> {polyglot.tr(featuresTranslations.officeCompatible)}</Title>
          <Paragraph>{polyglot.tr(featuresTranslations.officeCompatiblePar)}</Paragraph>
        </Box3>
        <Box4>
          <ConvertIcon />
          <Title> {polyglot.tr(featuresTranslations.textVersion)}</Title>
          <Paragraph>{polyglot.tr(featuresTranslations.textVersionPar)}</Paragraph>
        </Box4>
        <Box5>
          <LanguageBook />
          <Title> {polyglot.tr(featuresTranslations.bilingual)}</Title>
          <Paragraph>{polyglot.tr(featuresTranslations.bilingualPar)}</Paragraph>
        </Box5>
        <Box6>
          <AutomaticLegend />
          <Title> {polyglot.tr(featuresTranslations.smartLegend)}</Title>
          <Paragraph>{polyglot.tr(featuresTranslations.smartLegendPar)}</Paragraph>
        </Box6>
        <Box7>
          <PaintingIcon />
          <Title> {polyglot.tr(featuresTranslations.styling)}</Title>
          <Paragraph>{polyglot.tr(featuresTranslations.stylingPar)}</Paragraph>
        </Box7>
        <Box8>
          <Keyboard />
          <Title> {polyglot.tr(featuresTranslations.writeLess)}</Title>
          <Paragraph>{polyglot.tr(featuresTranslations.writeLessPar)}</Paragraph>
        </Box8>
      </Grid>
    </div>
  )
}

export default FeaturesOther
