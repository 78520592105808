import React from 'react'

const BalanceSVG = props => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 71.67 64"
    preserveAspectRatio=" none "
    height={props.height}
  >
    <title>test</title>
    <path
      d="M35.82,10.65a1.23,1.23,0,0,1-1.23-1.23V3.65a1.23,1.23,0,0,1,2.46,0h0V9.42A1.23,1.23,0,0,1,35.82,10.65Z"
      transform="translate(0.03)"
      style={{ fill: '#9d9da0' }}
    />
    <path
      d="M35.82,46.19A1.23,1.23,0,0,1,34.59,45V13A1.23,1.23,0,0,1,37,13h0V45A1.23,1.23,0,0,1,35.82,46.19Z"
      transform="translate(0.03)"
      style={{ fill: '#9d9da0' }}
    />
    <path
      d="M67.95,47.53,60.22,22.82,52.48,47.53,50.76,47l8.59-27.45a.89.89,0,0,1,1.71,0L69.65,47Z"
      transform="translate(0.03)"
      style={{ fill: '#9d9da0' }}
    />
    <path
      d="M19.16,47.53,11.42,22.82,3.69,47.53,2,47l8.59-27.45a.89.89,0,0,1,1.71,0L20.82,47Z"
      transform="translate(0.03)"
      style={{ fill: '#9d9da0' }}
    />
    <circle cx="35.85" cy="3.25" r="3.25" style={{ fill: '#c1c1c1' }} />
    <path
      d="M39.32,46.68v6.8h-7v-6.8A1.7,1.7,0,0,1,34,45h3.59A1.71,1.71,0,0,1,39.32,46.68Z"
      transform="translate(0.03)"
      style={{ fill: '#c1c1c1' }}
    />
    <path
      d="M37.12,46.68v6.8H34.5v-6.8c0-1,.28-1.71.64-1.71h1.34C36.82,45,37.12,45.74,37.12,46.68Z"
      transform="translate(0.03)"
      style={{ fill: '#e5e5e5' }}
    />
    <path
      d="M71.64,46.4v1.46a5.85,5.85,0,0,1-5.82,5.84H54.64a5.85,5.85,0,0,1-5.82-5.84h0V46.4Z"
      transform="translate(0.03)"
      style={{ fill: '#9d9da0' }}
    />
    <path
      d="M22.82,46.4v1.46A5.85,5.85,0,0,1,17,53.71H5.82A5.84,5.84,0,0,1,0,47.88H0V46.41H22.82Z"
      transform="translate(0.03)"
      style={{ fill: '#9d9da0' }}
    />
    <path
      d="M17.82,46.4v1.46c0,3.23-1.47,5.85-3.27,5.85H8.28C6.48,53.71,5,51.09,5,47.86V46.4Z"
      transform="translate(0.03)"
      style={{ fill: '#cecece' }}
    />
    <path
      d="M66.63,46.4v1.46c0,3.23-1.47,5.85-3.27,5.85H57.09c-1.8,0-3.27-2.62-3.27-5.85V46.4Z"
      transform="translate(0.03)"
      style={{ fill: '#ccc' }}
    />
    <path
      d="M67,13.42a1.26,1.26,0,0,0-1.57.71,6,6,0,0,1-3.34,3.64c-2.14.76-4.52-.27-5.75-.94h-.06l-1.06-.48a33.4,33.4,0,0,1-5.07-2.74c-6-4-14.34-4.19-14.34-4.19s-8.35.21-14.34,4.16a33.4,33.4,0,0,1-5.07,2.74l-1.06.48h-.06c-1.23.67-3.62,1.69-5.75.94A6,6,0,0,1,6.2,14.1a1.26,1.26,0,0,0-1.57-.71,1.22,1.22,0,0,0-.74,1.56l0,.05v.05a8.32,8.32,0,0,0,4.8,5,9.6,9.6,0,0,0,7.72-1c11-5,19.28-4.86,19.35-4.86h0a31.68,31.68,0,0,1,5.53.52A56,56,0,0,1,55.2,19a11.19,11.19,0,0,0,5.26,1.48,7,7,0,0,0,2.43-.41,8.28,8.28,0,0,0,4.8-5,1.18,1.18,0,0,0-.59-1.56Z"
      transform="translate(0.03)"
      style={{ fill: '#c1c1c1' }}
    />
    <path
      d="M43.82,56.42v1.93h-16V56.42A3.17,3.17,0,0,1,31,53.24h9.64a3.18,3.18,0,0,1,3.2,3.16Z"
      transform="translate(0.03)"
      style={{ fill: '#9d9da0' }}
    />
    <path
      d="M40.23,56.42v1.93H31.4V56.42c0-1.76.78-3.18,1.76-3.18h5.32C39.44,53.24,40.23,54.67,40.23,56.42Z"
      transform="translate(0.03)"
      style={{ fill: '#ccc' }}
    />
    <path
      d="M56.46,62.31V64H15.18V62.31a4.2,4.2,0,0,1,4.2-4.2H52.26a4.18,4.18,0,0,1,4.2,4.16h0Z"
      transform="translate(0.03)"
      style={{ fill: '#9d9da0' }}
    />
    <path
      d="M51.13,62.31V64H20.51V62.31c0-2.32,1.4-4.2,3.12-4.2H48C49.73,58.1,51.13,60,51.13,62.31Z"
      transform="translate(0.03)"
      style={{ fill: '#cecece' }}
    />
  </svg>
)

export default BalanceSVG
