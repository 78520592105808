import React from 'react'

const LanguageBook = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="60px" viewBox="0 0 71.661 87.196">
    <g id="svg_1">
      <path
        d="M22.309587104797366,56.70219570159912 l0.12000000000000001,0.08300000000000002 h0.131 c0,0 0,0 0.0010000000000000002,0 c0.48000000000000004,0 2.952,-1.573 3.702,-2.8600000000000003 c0.04300000000000001,-0.04800000000000001 0.16200000000000003,-0.15600000000000003 0.27,-0.255 c0.03900000000000001,-0.035 0.07600000000000001,-0.07 0.11000000000000001,-0.10200000000000001 h13.033 v-5.757 h7.958 c0.9520000000000001,1.338 3.241,2.79 3.8360000000000003,2.79 l0.24400000000000002,-0.010000000000000002 l0.17300000000000001,-0.139 c0.33000000000000007,-0.254 0.47700000000000004,-0.7620000000000001 -0.5630000000000001,-2.6420000000000003 h1.792 v-9.743 H34.03958710479736 v6.356 H21.19858710479736 v9.144 h2.286 C22.880587104797364,54.53319570159912 21.84858710479736,56.333195701599124 22.309587104797366,56.70219570159912 zM35.39958710479736,39.42519570159912 h16.356 v7.024 h-2.8810000000000002 l0.6900000000000002,1.052 c0.16200000000000003,0.24600000000000002 0.31800000000000006,0.51 0.462,0.772 c-0.5880000000000001,-0.453 -1.129,-0.9660000000000001 -1.397,-1.4260000000000002 c-0.03900000000000001,-0.06300000000000001 -0.08500000000000002,-0.11400000000000002 -0.16700000000000004,-0.201 l-0.19900000000000004,-0.19700000000000004 h-8.587 v-2.027 h-4.277 V39.42519570159912 z"
        id="svg_2"
      />
      <path
        d="M60.10458710479736,11.43819570159912 V3.767195701599121 H12.839587104797364 c-4.341,0 -9.013,1.3010000000000002 -9.013,4.157 v62.626 C3.826587104797362,82.65619570159912 5.364587104797362,83.76719570159912 22.117587104797366,83.76719570159912 h44.658 V11.43819570159912 H60.10458710479736 zM6.509587104797362,7.9221957015991205 l0.19600000000000004,-0.15200000000000002 c0.5930000000000001,-0.457 2.636,-1.3210000000000002 6.134,-1.3210000000000002 h45.18 v4.99 h-45.18 c-3.498,0 -5.542,-0.8630000000000001 -6.134,-1.32 L6.509587104797362,9.96719570159912 V7.9221957015991205 zM64.09258710479736,81.08419570159911 H22.117587104797366 c-8.797,0 -12.74,-0.266 -14.385,-1.916 c-1.258,-1.266 -1.248,-3.482 -1.229,-7.155 l0.005000000000000001,-58.983 L7.181587104797362,13.267195701599121 c1.5710000000000002,0.55 3.58,0.8530000000000001 5.657,0.8530000000000001 h51.254 V81.08419570159911 z"
        id="svg_3"
      />
    </g>
  </svg>
)

export default LanguageBook
