export const featuresTranslations = {
  transactionsSchemas: {
    en: 'Tax Transactions',
    fr: 'Transactions Fiscales'
  },
  transactionsSchemasPar: {
    en: 'Represent your tax transactions in different schematic steps',
    fr: 'Représentez vos transactions fiscales en différentes étapes schématisées.'
  },
  automaticLayout: {
    en: 'Automatic Layout',
    fr: 'Arrangement Automatique'
  },
  automaticLayoutPar: {
    en: 'The orgchart is built autonomously as the user enters the corporate group data.',
    fr:
      "L'organigramme se bâtit de façon autonome au fur et à mesure que l'utilisateur entre les données du groupe corporatif."
  },
  writeLess: {
    en: 'Autocomplete',
    fr: 'Autocomplétion'
  },
  writeLessPar: {
    en: 'Type less, build more.',
    fr: 'Écrivez moins, créez plus. '
  },
  manageShares: {
    en: 'Manage shares quickly',
    fr: 'Gérer les actions rapidement'
  },
  manageSharesPar: {
    en:
      'With an interface specifically designed to manage shares, describing a business share-capital has never been so simple.',
    fr:
      "Avec une interface spécifiquement prévue pour gérer les actions, décrire le capital-action d'une société n'a jamais été aussi rapide."
  },

  textVersion: {
    en: 'Convert Orcharts to Text',
    fr: "Convertisseur d'organigrammes"
  },
  textVersionPar: {
    en: 'Convert a orgchart into plaintext so you can describe a corporate structure in plain words.',
    fr:
      'Convertissez un organigramme en texte. De cette façon, vous aurez une description textuelle de la structure corporative.'
  },
  cloudBased: {
    en: 'Cloud Based',
    fr: 'Basé sur le Cloud'
  },
  backUp: {
    en: 'Backup your work safely.',
    fr: 'Sauvegardez votre travail en toute sécurité.'
  },

  collab: {
    en: 'Shares orgcharts between colleagues.',
    fr: 'Partagez vos organigrammes entre collègues.'
  },
  entity: {
    en: 'Collaborative Space',
    fr: 'Espace Collaboratif'
  },
  entityPar: {
    en: 'Add other members of your firm to collaborate on your orgcharts creation.',
    fr: 'Ajoutez les membres de votre cabinet afin de collaborer sur la création de vos organigrammes.'
  },
  status: {
    en: 'Track Status',
    fr: "Suivi de l'avancement"
  },
  statusPar: {
    en: 'Follow the completion status of a orgchart in real time.',
    fr: "Suivez l'état de l'avancement d'un organigramme en temps réelle."
  },
  bilingual: {
    en: 'Bilingual',
    fr: 'Bilingue'
  },
  bilingualPar: {
    en:
      'Orgcharts can be created in french or in english. The entirety of Redox and its features are also available in those two languages at all times.',
    fr:
      "Les organigrammes peuvent être créés en anglais et en français. L'entièreté de Redox et de ses fonctionnalités sont également disponibles dans ces deux langues en tout temps."
  },
  complete: {
    en: 'A platform rich in features.',
    fr: 'Une plateforme complète.'
  },
  styling: {
    en: 'Custom styling',
    fr: 'Style personnalisé'
  },
  stylingPar: {
    en: 'Choose how your orgcharts look, so it can reflect the branding of your firm.',
    fr: "Choisissez l'apparence de vos organigrammes pour que l'image de marque de votre cabinet s'y réflète."
  },
  confidentialMode: {
    en: 'Choose how how your orgcharts look, so it can reflect the branding of your firm.',
    fr: ''
  },
  export: {
    en: 'Export',
    fr: 'Export'
  },
  exportPar: {
    en: 'Save and export your orgchart as an image or a PDF.',
    fr: "Sauvegardez et exportez vos organigrammes sous forme d'image ou de PDF."
  },
  smartLegend: { en: 'Smart legend', fr: 'Légende intelligente' },
  smartLegendPar: {
    en:
      'Redox knows when fiscal acronyms are used in orchcharts. The legend of a orgchart will automatically be adjusted in order to define those terms.',
    fr:
      "Redox reconnait lorsque des acronymes sont utilisés dans les orgnigrammes. La légende de votre orgnigramme s'ajustera automatiquement  afin de les définir."
  },
  officeCompatible: { en: 'Compatible with Office suite', fr: 'Compatible avec la suite Office' },
  officeCompatiblePar: {
    en:
      'A orgchart created with Redox can then be used in any of Microsoft Office software (Word, Powerpoint, Excel, etc.).',
    fr:
      "Un organigramme créé avec Redox peut ensuite être utilisé dans n'importe quel logiciel de la suite Microsoft Office (Word, Powerpoint, Excel, etc.)"
  }
}
